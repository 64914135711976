import {
  Card,
  Classes,
  Elevation,
  H2,
  H5,
  Icon,
  Intent,
  NonIdealState,
  Text,
} from '@blueprintjs/core';
import moment from 'moment';
import * as React from 'react';
import {useHistory} from 'react-router-dom';

import {Gazebo} from '@slices/Gazebo';

import {getResponseData, getResponseError} from 'src/ice-client-react';
import {centsToMainUnits} from 'src/utils/numbers';

import {useTournaments} from '../../store/tournaments';
import {getClasses} from '../../utils/css';

const classes = getClasses({
  root: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: 5,
  },
  tournamentCard: {
    display: 'grid',
    gridGap: 5,
    maxWidth: 230,
    $nest: {
      [`& .${Classes.ICON}`]: {
        marginRight: 10,
      },
    },
  },
  cardHeader: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    paddingTop: 5,
  },
});

export const Tournaments: React.FunctionComponent<{
  areaId?: string;
}> = ({areaId}) => {
  const tournamentsResponse = useTournaments();
  const rawTournaments = getResponseData(tournamentsResponse);
  if (getResponseError(tournamentsResponse)) {
    return <div>Error</div>;
  }
  const tournaments = [...(rawTournaments ?? [])]
    .sort(({order: a = ''}, {order: b = ''}) =>
      a.localeCompare(b, undefined, {
        numeric: false,
        sensitivity: 'base',
      }),
    )
    .map((tournament) => (
      <TournamentCard
        key={tournament.tournamentId}
        areaId={areaId}
        tournament={tournament}
      />
    ));
  return (
    <>
      <H2>Tournaments</H2>
      <div className={classes.root}>
        {tournaments.length !== 0 ? (
          tournaments
        ) : (
          <NonIdealState title="No tournaments" />
        )}
      </div>
    </>
  );
};

const TournamentCard: React.FunctionComponent<{
  areaId?: string;
  tournament: Gazebo.Lobby.AbstractTournament;
}> = ({tournament, areaId}) => {
  const history = useHistory();
  const {tournamentId} = tournament;
  const path = areaId ?? 'global';
  const goToTournament = React.useCallback(() => {
    history.push(`/${path}/${tournamentId}`);
  }, [history, tournamentId, path]);
  return (
    <Card
      className={classes.tournamentCard}
      interactive={true}
      onClick={goToTournament}
      elevation={Elevation.TWO}
    >
      {tournament instanceof Gazebo.Lobby.MultiTableTournament ? (
        <>
          <H5 className={classes.cardHeader}>
            {tournament.name}
            <Text
              className={Classes.TEXT_MUTED}
            >{`#${tournament.tournamentId}`}</Text>
          </H5>
          <TournamentStatus status={tournament.status} />
          <span>
            <Icon icon="settings" /> {tournament.gameType}
          </span>
          {tournament.startAtMs != null ? (
            <span>
              <Icon icon="time" />
              {`${moment(tournament.startAtMs.toNumber())}`}
            </span>
          ) : null}
          <span>
            <Icon icon={'log-in'} />
            {'Buy-in: '}
            {centsToMainUnits(tournament.buyIn.toNumber())}
          </span>
          <span>
            <Icon icon={'user'} />
            {tournament.isHeroRegistered ? 'Registered' : 'Not registered'}
          </span>
          {tournament.minPrizePool != null ? (
            <span>
              <Icon icon={'chart'} />
              {'Guarantee: '}
              {centsToMainUnits(tournament.minPrizePool.toNumber())}
            </span>
          ) : null}
        </>
      ) : null}
    </Card>
  );
};

const TournamentStatus: React.FunctionComponent<{
  status: Gazebo.Lobby.AbstractTournamentStatus;
}> = ({status}) => {
  if (status instanceof Gazebo.Lobby.TournamentRegistrationOpen) {
    return (
      <span>
        <Icon intent={Intent.PRIMARY} icon="info-sign" />
        Registration open
      </span>
    );
  }
  if (status instanceof Gazebo.Lobby.TournamentRegistrationClosed) {
    return (
      <span>
        <Icon intent={Intent.PRIMARY} icon="info-sign" />
        Registration closed
      </span>
    );
  }
  if (status instanceof Gazebo.Lobby.TournamentInProgress) {
    return (
      <span>
        <Icon intent={Intent.SUCCESS} icon="info-sign" />
        In progress
      </span>
    );
  }
  return null;
};
