import * as React from 'react';

import {KillTable} from '../../components/killTable';
import {getResponseData, getResponseError} from '../../ice-client-react';
import {useAllTables} from '../../store/tables';
import {centsToMainUnits} from '../../utils/numbers';

import {Swimlane, Tables, tablesClasses} from './tables';

export const AllTablesManagement: React.FunctionComponent = () => {
  const allAreasLobby = useAllTables();
  const error = getResponseError(allAreasLobby);
  const rawTables = getResponseData(allAreasLobby);

  const tables = React.useMemo(() => {
    const groups: Record<string, Tables> = {};
    for (const table of rawTables ?? []) {
      const limit = `${centsToMainUnits(
        table.smallBlind?.toNumber(),
      )}/${centsToMainUnits(table.bigBlind?.toNumber())}`;
      groups[limit] = groups[limit] ?? [];
      groups[limit].push(table);
    }
    return [...Object.entries(groups)].sort(
      ([limit1], [limit2]) => -limit1.localeCompare(limit2),
    );
  }, [rawTables]);

  if (error != null) {
    return <div>error</div>;
  }
  return (
    <div className={tablesClasses.root}>
      <KillTable />
      {tables.map(([limit, tables]) => (
        <Swimlane key={limit} limit={limit} tables={tables} />
      ))}
    </div>
  );
};
